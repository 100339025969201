<script setup lang="ts"></script>

<template>
    <svg
        width="855"
        height="36"
        viewBox="0 0 855 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.7215 8.13871C13.7067 7.43263 12.3824 7.07874 10.7486 7.07874H4.42367C3.92283 7.07874 3.64613 7.33223 3.59357 7.83862L1.02412 24.1618C0.997414 24.3221 1.03705 24.4687 1.14265 24.602C1.24765 24.7356 1.37972 24.802 1.53789 24.802H4.5422C5.06914 24.802 5.35877 24.5491 5.41194 24.042L6.12338 19.6414C6.14936 19.4281 6.24204 19.2547 6.4002 19.1212C6.55825 18.9879 6.75593 18.9007 6.99312 18.8607C7.23031 18.8211 7.45396 18.8012 7.66517 18.8012C7.87578 18.8012 8.12602 18.8146 8.41637 18.8412C8.706 18.8678 8.89063 18.8808 8.96977 18.8808C11.2361 18.8808 13.0149 18.2345 14.3065 16.9406C15.5974 15.6472 16.2436 13.8536 16.2436 11.5595C16.2436 9.98608 15.7359 8.84589 14.7215 8.13871ZM11.4603 13.0399C11.3281 13.9736 10.9859 14.5867 10.4325 14.8802C9.87902 15.1741 9.08843 15.3201 8.06077 15.3201L6.75617 15.3602L7.42835 11.0793C7.48079 10.7862 7.652 10.6395 7.94211 10.6395H8.69343C9.74707 10.6395 10.5118 10.7932 10.9862 11.0994C11.4603 11.4063 11.6185 12.0534 11.4603 13.0399Z"
            fill="#7C87AB"
        />
        <path
            d="M81.4698 7.07874H78.5447C78.2541 7.07874 78.083 7.22548 78.0308 7.51884L75.4612 24.1618L75.4215 24.2418C75.4215 24.3758 75.4743 24.5019 75.5799 24.622C75.6848 24.7418 75.8171 24.8018 75.9751 24.8018H78.5844C79.0844 24.8018 79.3612 24.549 79.4146 24.0418L81.984 7.67855V7.6388C81.9839 7.26547 81.8121 7.07874 81.4698 7.07874Z"
            fill="#7C87AB"
        />
        <path
            d="M46.129 13.5198C46.129 13.3867 46.0759 13.2598 45.9712 13.14C45.8654 13.02 45.7467 12.9597 45.6153 12.9597H42.5714C42.2809 12.9597 42.0438 13.0937 41.8598 13.3597L37.6695 19.6012L35.9303 13.6C35.798 13.1735 35.5083 12.9597 35.0606 12.9597H32.0954C31.9633 12.9597 31.8447 13.0199 31.7399 13.14C31.6342 13.2598 31.5818 13.3868 31.5818 13.5198C31.5818 13.5736 31.8388 14.36 32.3525 15.8803C32.8663 17.4008 33.4198 19.0411 34.0129 20.8016C34.6059 22.5617 34.9152 23.4957 34.9418 23.6017C32.7806 26.5892 31.7003 28.1895 31.7003 28.4026C31.7003 28.7496 31.8715 28.9228 32.2142 28.9228H35.2581C35.548 28.9228 35.7851 28.7897 35.9697 28.5229L46.0502 13.7998C46.1026 13.7467 46.129 13.6538 46.129 13.5198Z"
            fill="#7C87AB"
        />
        <path
            d="M74.4729 12.9597H71.4683C71.0992 12.9597 70.8758 13.3998 70.7968 14.28C70.1108 13.2137 68.8597 12.6795 67.041 12.6795C65.1435 12.6795 63.529 13.3998 62.1985 14.8401C60.8674 16.2805 60.2023 17.9744 60.2023 19.9212C60.2023 21.4951 60.6567 22.7485 61.5658 23.6817C62.4751 24.6159 63.6939 25.082 65.2229 25.082C65.9869 25.082 66.7643 24.9217 67.5549 24.6019C68.3456 24.2819 68.9644 23.8554 69.4131 23.3217C69.4131 23.3485 69.3863 23.4683 69.334 23.6816C69.2808 23.8953 69.2548 24.0557 69.2548 24.1617C69.2548 24.5889 69.4257 24.8017 69.7689 24.8017H72.4967C72.9967 24.8017 73.2871 24.5488 73.366 24.0417L74.9869 13.5999C75.0129 13.4398 74.9734 13.2933 74.8682 13.1598C74.7625 13.0266 74.6311 12.9597 74.4729 12.9597ZM69.3142 20.8413C68.6421 21.5081 67.8318 21.8413 66.8831 21.8413C66.1185 21.8413 65.4997 21.6283 65.0251 21.2013C64.5506 20.7752 64.3134 20.1884 64.3134 19.441C64.3134 18.4547 64.6428 17.6207 65.3018 16.9406C65.9599 16.2604 66.7776 15.9204 67.7528 15.9204C68.49 15.9204 69.1028 16.1405 69.5909 16.5805C70.0781 17.0206 70.3224 17.6276 70.3224 18.401C70.3223 19.3611 69.9861 20.1748 69.3142 20.8413Z"
            fill="#7C87AB"
        />
        <path
            d="M29.5656 12.9597H26.5613C26.1918 12.9597 25.9682 13.3998 25.8891 14.28C25.1775 13.2137 23.9256 12.6795 22.1336 12.6795C20.2361 12.6795 18.6217 13.3998 17.291 14.8401C15.9599 16.2805 15.2947 17.9744 15.2947 19.9212C15.2947 21.4951 15.7494 22.7485 16.6587 23.6817C17.5679 24.6159 18.7865 25.082 20.3152 25.082C21.0528 25.082 21.8175 24.9217 22.608 24.6019C23.3986 24.2819 24.0311 23.8554 24.5055 23.3217C24.3998 23.6417 24.3473 23.9219 24.3473 24.1617C24.3473 24.5889 24.5185 24.8017 24.8612 24.8017H27.5888C28.0892 24.8017 28.3794 24.5488 28.4586 24.0417L30.0792 13.5999C30.1052 13.4398 30.0657 13.2933 29.9607 13.1598C29.8552 13.0266 29.7236 12.9597 29.5656 12.9597ZM24.4068 20.8611C23.7346 21.5153 22.9106 21.8413 21.9362 21.8413C21.1714 21.8413 20.5587 21.6283 20.0978 21.2013C19.6364 20.7752 19.406 20.1884 19.406 19.441C19.406 18.4547 19.7352 17.6207 20.3944 16.9406C21.0528 16.2604 21.8698 15.9204 22.8453 15.9204C23.5828 15.9204 24.1956 16.1405 24.6836 16.5805C25.1708 17.0206 25.4148 17.6276 25.4148 18.401C25.4148 19.3879 25.0788 20.2083 24.4068 20.8611Z"
            fill="#7C87AB"
        />
        <path
            d="M59.6288 8.13871C58.6141 7.43263 57.29 7.07874 55.656 7.07874H49.3706C48.8432 7.07874 48.5532 7.33223 48.5009 7.83862L45.9314 24.1617C45.9047 24.322 45.9442 24.4686 46.0499 24.6019C46.1546 24.7354 46.287 24.8018 46.4452 24.8018H49.6866C50.0028 24.8018 50.2135 24.6287 50.3191 24.2819L51.0308 19.6413C51.0569 19.428 51.1492 19.2546 51.3075 19.1211C51.4657 18.9878 51.6631 18.9006 51.9005 18.8606C52.1376 18.821 52.3612 18.801 52.5726 18.801C52.7832 18.801 53.0334 18.8145 53.3234 18.841C53.6133 18.8677 53.7983 18.8807 53.8768 18.8807C56.1435 18.8807 57.9221 18.2344 59.2136 16.9405C60.5049 15.6471 61.1507 13.8535 61.1507 11.5594C61.1508 9.98608 60.6432 8.84589 59.6288 8.13871ZM55.5769 14.7201C54.997 15.1201 54.1273 15.3201 52.9679 15.3201L51.7029 15.3602L52.375 11.0793C52.4272 10.7862 52.5984 10.6395 52.8888 10.6395H53.6001C54.1797 10.6395 54.6409 10.6661 54.9841 10.7193C55.3262 10.7731 55.656 10.9393 55.9723 11.2193C56.2887 11.4995 56.4466 11.9063 56.4466 12.4397C56.4466 13.5599 56.1564 14.3199 55.5769 14.7201Z"
            fill="#7C87AB"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M154.629 31.3705C151.597 33.9872 147.663 35.5668 143.365 35.5668C133.775 35.5668 126 27.7018 126 18C126 8.29814 133.775 0.433228 143.365 0.433228C147.663 0.433228 151.597 2.01283 154.629 4.62946C157.661 2.01283 161.594 0.433228 165.892 0.433228C175.483 0.433228 183.258 8.29814 183.258 18C183.258 27.7018 175.483 35.5668 165.892 35.5668C161.594 35.5668 157.661 33.9872 154.629 31.3705Z"
            fill="#7C87AB"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M154.624 31.3705C158.358 28.1484 160.726 23.3539 160.726 18C160.726 12.6461 158.358 7.85159 154.624 4.62952C157.657 2.01285 161.59 0.433228 165.888 0.433228C175.478 0.433228 183.253 8.29814 183.253 18C183.253 27.7018 175.478 35.5668 165.888 35.5668C161.59 35.5668 157.657 33.9871 154.624 31.3705Z"
            fill="#374360"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M245.077 29.8253H238.563L233.677 11.1882C233.446 10.3309 232.953 9.57296 232.229 9.21575C230.422 8.31806 228.43 7.60364 226.258 7.24332V6.52579H236.752C238.201 6.52579 239.287 7.60364 239.468 8.85543L242.003 22.299L248.514 6.52579H254.848L245.077 29.8253ZM258.469 29.8253H252.317L257.383 6.52579H263.535L258.469 29.8253ZM271.494 12.9801C271.675 11.7252 272.761 11.0076 274.028 11.0076C276.02 10.8275 278.189 11.1878 280 12.0824L281.086 7.06589C279.276 6.34836 277.284 5.98804 275.477 5.98804C269.505 5.98804 265.16 9.21537 265.16 13.6945C265.16 17.102 268.238 18.8912 270.411 19.969C272.761 21.0438 273.666 21.7613 273.485 22.836C273.485 24.4481 271.675 25.1657 269.868 25.1657C267.695 25.1657 265.522 24.6283 263.534 23.7306L262.448 28.7502C264.62 29.6448 266.971 30.0051 269.143 30.0051C275.839 30.1822 280 26.9579 280 22.1185C280 16.0241 271.494 15.6669 271.494 12.9801ZM301.532 29.8253L296.647 6.52579H291.399C290.313 6.52579 289.227 7.24332 288.865 8.31806L279.818 29.8253H286.152L287.416 26.421H295.198L295.922 29.8253H301.532ZM292.306 12.7987L294.113 21.5799H289.047L292.306 12.7987Z"
            fill="#7C87AB"
        />
        <g clip-path="url(#clip0_1339_22558)">
            <path
                d="M362.532 36C372.473 36 380.532 27.9411 380.532 18C380.532 8.05887 372.473 0 362.532 0C352.591 0 344.532 8.05887 344.532 18C344.532 27.9411 352.591 36 362.532 36Z"
                fill="#374360"
            />
            <path
                d="M363.092 4.5V14.4788L371.526 18.2475L363.092 4.5Z"
                fill="#7C87AB"
            />
            <path
                d="M363.092 4.5L354.657 18.2475L363.092 14.4788V4.5Z"
                fill="#7C87AB"
            />
            <path
                d="M363.092 24.714V31.4944L371.532 19.818L363.092 24.714Z"
                fill="#7C87AB"
            />
            <path
                d="M363.092 31.4944V24.7129L354.657 19.818L363.092 31.4944Z"
                fill="#7C87AB"
            />
            <path
                d="M363.092 23.1446L371.526 18.2475L363.092 14.481V23.1446Z"
                fill="#7C87AB"
            />
            <path
                d="M354.657 18.2475L363.092 23.1446V14.481L354.657 18.2475Z"
                fill="#7C87AB"
            />
        </g>
        <g clip-path="url(#clip1_1339_22558)">
            <path
                d="M441.532 36C451.473 36 459.532 27.9411 459.532 18C459.532 8.05887 451.473 0 441.532 0C431.591 0 423.532 8.05887 423.532 18C423.532 27.9411 431.591 36 441.532 36Z"
                fill="#374360"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M435.262 21.6158L433.657 22.239L434.431 19.1351L436.055 18.4826L438.396 9H444.166L442.458 15.9705L444.044 15.3281L443.279 18.4219L441.673 19.0642L440.719 22.9826H449.407L448.425 27H433.94L435.262 21.6158Z"
                fill="#7C87AB"
            />
        </g>
        <g clip-path="url(#clip2_1339_22558)">
            <path
                d="M520.532 36C530.473 36 538.532 27.9411 538.532 18C538.532 8.05887 530.473 0 520.532 0C510.591 0 502.532 8.05887 502.532 18C502.532 27.9411 510.591 36 520.532 36Z"
                fill="#374360"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M522.694 19.5559V19.5536C522.57 19.5626 521.932 19.6009 520.509 19.6009C519.373 19.6009 518.573 19.5671 518.292 19.5536V19.557C513.918 19.3646 510.653 18.603 510.653 17.6918C510.653 16.7816 513.918 16.02 518.292 15.8243V18.7988C518.578 18.819 519.397 18.8674 520.528 18.8674C521.886 18.8674 522.567 18.8111 522.694 18.7999V15.8265C527.059 16.0211 530.316 16.7828 530.316 17.6918C530.316 18.603 527.059 19.3624 522.694 19.5559ZM522.694 15.5171V12.8554H528.785V8.79639H512.201V12.8554H518.292V15.516C513.342 15.7433 509.619 16.7243 509.619 17.8988C509.619 19.0733 513.342 20.0531 518.292 20.2815V28.8113H522.694V20.2793C527.636 20.052 531.35 19.0721 531.35 17.8988C531.35 16.7254 527.636 15.7455 522.694 15.5171Z"
                fill="#7C87AB"
            />
        </g>
        <g clip-path="url(#clip3_1339_22558)">
            <path
                d="M599.532 36C609.473 36 617.532 27.9411 617.532 18C617.532 8.05887 609.473 0 599.532 0C589.591 0 581.532 8.05887 581.532 18C581.532 27.9411 589.591 36 599.532 36Z"
                fill="#374360"
            />
            <path
                d="M604.056 20.3895C604.056 18 602.616 17.181 599.736 16.839C597.68 16.5657 597.269 16.02 597.269 15.0638C597.269 14.1075 597.956 13.4933 599.326 13.4933C600.56 13.4933 601.246 13.9028 601.588 14.9277C601.624 15.0268 601.689 15.1126 601.775 15.1735C601.861 15.2345 601.963 15.2676 602.069 15.2685H603.165C603.229 15.2702 603.292 15.259 603.351 15.2356C603.41 15.2122 603.463 15.1771 603.508 15.1324C603.553 15.0877 603.588 15.0343 603.612 14.9755C603.636 14.9168 603.647 14.8538 603.646 14.7904V14.7229C603.512 13.9814 603.137 13.305 602.579 12.7986C602.021 12.2923 601.311 11.9844 600.56 11.9228V10.2848C600.56 10.0114 600.354 9.80666 600.012 9.73804H598.983C598.709 9.73804 598.503 9.94279 598.435 10.2848V11.8553C596.377 12.1275 595.076 13.4933 595.076 15.201C595.076 17.4533 596.446 18.3409 599.326 18.6829C601.246 19.0238 601.863 19.4344 601.863 20.5268C601.863 21.618 600.903 22.3695 599.6 22.3695C597.817 22.3695 597.201 21.6192 596.995 20.5943C596.927 20.322 596.72 20.1848 596.514 20.1848H595.349C595.286 20.1833 595.223 20.1946 595.164 20.218C595.105 20.2415 595.052 20.2766 595.007 20.3213C594.962 20.366 594.927 20.4193 594.903 20.4781C594.88 20.5368 594.868 20.5997 594.87 20.6629V20.7304C595.143 22.4382 596.241 23.6667 598.503 24.0087V25.6478C598.503 25.92 598.709 26.1259 599.051 26.1934H600.081C600.354 26.1934 600.56 25.9887 600.629 25.6478V24.0075C602.686 23.6667 604.056 22.2323 604.056 20.3884V20.3895Z"
                fill="#7C87AB"
            />
            <path
                d="M596.035 27.5591C590.687 25.6466 587.944 19.7066 589.933 14.4495C590.961 11.5807 593.224 9.39713 596.035 8.37225C596.31 8.23612 596.446 8.03138 596.446 7.68938V6.73313C596.446 6.46088 596.31 6.25613 596.035 6.1875C595.967 6.1875 595.829 6.1875 595.761 6.255C594.218 6.73686 592.785 7.51919 591.546 8.55694C590.306 9.59469 589.285 10.8674 588.539 12.3017C587.793 13.736 587.339 15.3036 587.202 16.9143C587.065 18.525 587.247 20.1469 587.739 21.6866C588.972 25.5116 591.921 28.4479 595.761 29.6764C596.035 29.8125 596.31 29.6764 596.377 29.403C596.446 29.3355 596.446 29.2657 596.446 29.1296V28.1734C596.446 27.9686 596.241 27.6964 596.035 27.5591ZM603.303 6.25612C603.028 6.11887 602.754 6.25612 602.686 6.52837C602.618 6.597 602.618 6.66563 602.618 6.80175V7.758C602.618 8.03137 602.822 8.30362 603.028 8.44087C608.376 10.3534 611.119 16.2934 609.13 21.5505C608.102 24.4193 605.84 26.6029 603.028 27.6278C602.754 27.7639 602.618 27.9686 602.618 28.3106V29.2669C602.618 29.5391 602.754 29.7439 603.028 29.8125C603.097 29.8125 603.234 29.8125 603.303 29.745C604.846 29.2631 606.278 28.4808 607.518 27.4431C608.757 26.4053 609.779 25.1326 610.525 23.6983C611.27 22.264 611.725 20.6964 611.862 19.0857C611.999 17.475 611.816 15.8531 611.324 14.3134C610.091 10.4209 607.074 7.48462 603.303 6.25612Z"
                fill="#7C87AB"
            />
        </g>
        <g clip-path="url(#clip4_1339_22558)">
            <path
                d="M678.532 36C688.473 36 696.532 27.9411 696.532 18C696.532 8.05887 688.473 0 678.532 0C668.591 0 660.532 8.05887 660.532 18C660.532 27.9411 668.591 36 678.532 36Z"
                fill="#374360"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M675.436 16.4362H680.289V19.008H675.436V24.4283H678.497C679.708 24.4283 680.7 24.2651 681.471 23.9366C682.243 23.6093 682.848 23.1559 683.288 22.5754C683.738 21.9717 684.044 21.2734 684.184 20.5335C684.346 19.6989 684.425 18.8502 684.42 18C684.425 17.1498 684.346 16.3011 684.184 15.4665C684.045 14.7266 683.738 14.0282 683.288 13.4246C682.848 12.8441 682.242 12.3907 681.471 12.0634C680.7 11.7349 679.708 11.5718 678.497 11.5718H675.436V16.4374V16.4362ZM672.324 19.008H670.657V16.4374H672.324V9H679.692C681.053 9 682.231 9.23625 683.226 9.70537C684.222 10.1767 685.034 10.8191 685.665 11.6347C686.295 12.4492 686.764 13.4033 687.072 14.4956C687.379 15.588 687.532 16.7569 687.532 18C687.539 19.1838 687.384 20.3629 687.07 21.5044C686.764 22.5956 686.295 23.5508 685.664 24.3653C685.034 25.1809 684.222 25.8232 683.226 26.2946C682.231 26.7649 681.052 27 679.692 27H672.324V19.008Z"
                fill="#7C87AB"
            />
        </g>
        <g clip-path="url(#clip5_1339_22558)">
            <path
                d="M757.532 36C767.473 36 775.532 27.9411 775.532 18C775.532 8.05887 767.473 0 757.532 0C747.591 0 739.532 8.05887 739.532 18C739.532 27.9411 747.591 36 757.532 36Z"
                fill="#374360"
            />
            <path
                d="M753.162 16.2045L757.532 11.835L761.903 16.2067L764.446 13.6642L757.532 6.75L750.62 13.662L753.162 16.2045ZM746.282 18L748.824 15.4575L751.367 18L748.824 20.5425L746.282 18ZM753.162 19.7955L757.532 24.165L761.903 19.7933L764.446 22.3346L757.532 29.25L750.62 22.338L750.616 22.3346L753.162 19.7955ZM763.697 18L766.239 15.4575L768.782 18L766.239 20.5425L763.697 18ZM760.11 17.9978H760.112V18L757.532 20.5808L754.954 18.0045L754.95 18L754.954 17.9966L755.405 17.5444L755.625 17.325L757.532 15.4193L760.111 17.9989L760.11 17.9978Z"
                fill="#7C87AB"
            />
        </g>
        <g clip-path="url(#clip6_1339_22558)">
            <path
                d="M836.532 36C846.473 36 854.532 27.9411 854.532 18C854.532 8.05887 846.473 0 836.532 0C826.591 0 818.532 8.05887 818.532 18C818.532 27.9411 826.591 36 836.532 36Z"
                fill="#374360"
            />
            <path
                d="M843.205 11.1522L826.969 8.16418L835.514 29.6652L847.419 15.1594L843.205 11.1522ZM842.944 12.4684L845.428 14.8298L838.635 16.0594L842.944 12.4684ZM837.159 15.8131L830 9.87531L841.702 12.0286L837.159 15.8131ZM836.65 16.8638L835.482 26.5163L829.188 10.6729L836.65 16.8638ZM837.73 17.3757L845.253 16.0144L836.624 26.5253L837.73 17.3757Z"
                fill="#7C87AB"
            />
        </g>
        <defs>
            <clipPath id="clip0_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(344.532)"
                />
            </clipPath>
            <clipPath id="clip1_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(423.532)"
                />
            </clipPath>
            <clipPath id="clip2_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(502.532)"
                />
            </clipPath>
            <clipPath id="clip3_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(581.532)"
                />
            </clipPath>
            <clipPath id="clip4_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(660.532)"
                />
            </clipPath>
            <clipPath id="clip5_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(739.532)"
                />
            </clipPath>
            <clipPath id="clip6_1339_22558">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(818.532)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
